.shadow {
  &--extra-small {
    box-shadow: 0px 1px 3px rgba(17, 24, 39, 0.07);
  }

  &--small {
    box-shadow: 0px 4px 6px -1px rgba(17, 24, 39, 0.07);
  }

  &--medium {
    box-shadow: 0px 10px 15px -3px rgba(17, 24, 39, 0.07);
  }

  &--large {
    box-shadow: 0px 20px 25px -5px rgba(17, 24, 39, 0.07);
  }

  &--extra-large {
    box-shadow: 0px 25px 50px -12px rgba(17, 24, 39, 0.17);
  }
}

.hidden {
  display: none !important;
}

.flex {
  display: flex;

  &--row {
    flex-direction: row;
  }
}

.border {
  border: 1px solid var(--ion-color-primary);
}

.bg-grey-100 {
  background-color: var(--ion-color-greyscale-100) !important
}

.bg-grey-200 {
  background-color: var(--ion-color-greyscale-200) !important;
}

.ion-margin-top-0 {
  margin-top: 0 !important;
}

.ion-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.ion-margin-left-16 {
  margin-left: var(--app-size-16);
}

.ion-margin-bottom-32 {
  margin-bottom: var(--app-size-32);
}

.ion-margin-bottom-16 {
  margin-bottom: var(--app-size-16);
}

.ion-margin-top-24 {
  margin-top: var(--app-size-24);
}

.ion-margin-top-32 {
  margin-top: var(--app-size-32);
}

.ion-margin-top-48 {
  margin-top: var(--app-size-48);
}

.ion-padding-top-16{
  padding-top: var(--app-size-16);
}

.ion-padding-top-32{
  padding-top: var(--app-size-32);
}
