strong {
  font-family: var(--ion-font-family-medium);
}

p {
  font-size: 14px;
  line-height: 16px;

  &.extra-small {
    font-size: 11px;
    line-height: 12px;
  }

  &.small {
    font-size: 12px;
    line-height: 16px;
  }

  &.medium {
    font-size: 14px;
    line-height: 16px;
  }

  &.large {
    font-size: 16px;
    line-height: 20px;
  }

  &.link {
    &--icon {
      .icon {
        position: relative;
        top: 6px;
        margin-right: var(--app-size-8);
      }

      ion-img {
        width: 20px;
        display: inline-flex;
        margin-right: var(--app-size-8);
        position: relative;
        top: 4px;
      }

    }
  }

  &.icon {
    position: relative;
    padding-left: var(--app-size-24);
    padding-top: 0 !important;
    margin-top: var(--app-size-16) !important;

    .icon {
      position: absolute;
      left: 0;
      top: 1px;
    }
  }

  strong {
    font-family: var(--ion-font-family-medium);
    font-weight: initial;
  }

  &.error-message {
    color: var(--ion-color-danger);
    font-size: 12px;
    margin-bottom: 0;
  }

  &.no-more-items {
    width: 100%;
    text-align: center;
    margin: var(--app-size-40) 0;
    color: var(--ion-color-greyscale-500);
  }
}

h1 {
  font-size: 40px;
  line-height: 40px;
  font-family: var(--ion-font-family-medium);

}

h2 {
  font-size: 32px;
  line-height: 36px;
  font-family: var(--ion-font-family-medium);

}

h3 {
  font-size: 28px;
  line-height: 32px;
  font-family: var(--ion-font-family-medium);
}

h4 {
  font-size: 24px;
  line-height: 28px;
  font-family: var(--ion-font-family-medium);

}

h5 {
  font-size: 20px;
  line-height: 24px;
  font-family: var(--ion-font-family-medium);

  span {
    color: var(--ion-color-greyscale-500);
    font-size: 14px;
    font-family: var(--ion-font-family-regular);
  }

}

h6 {
  font-size: 16px;
  line-height: 20px;
  font-family: var(--ion-font-family-medium);

}
