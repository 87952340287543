ion-action-sheet {
  &.map-action-sheet, &.apps-action-sheet {
    --background: var(--ion-color-light);
    --button-color: var(--ion-color-primary);

    .action-sheet-group {
      position: relative;
    }

    .action-sheet-title {
      color: var(--ion-color-primary);
      font-size: 18px;
      font-family: var(--ion-font-family-medium);
    }

    .action-sheet-button {
      &-inner {
        flex-direction: row-reverse;
        justify-content: space-between;

        ion-icon {
          margin-right: 0;
          color: transparent;
          padding: 0;
        }
      }

      &.active {
        ion-icon {
          color: var(--ion-color-primary);
        }
      }

    }

    .close {
      position: absolute;
      right: 10px;
      top: 12px;
      width: 40px;
      height: 40px;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .action-sheet-button-inner {
        justify-content: center;
      }

      ion-icon {
        margin: 0;
        padding: 0;
        font-size: 28px;
        color: var(--ion-color-primary);
      }
    }
  }

  &.apps-action-sheet {
    .action-sheet-button {
      &-inner {
        flex-direction: row;
        justify-content: space-between;

      }

    }
  }
}

