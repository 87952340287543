ion-button {
  font-family: var(--ion-font-family-medium);
  font-size: 14px;
  line-height: 16px;
  height: var(--app-size-48);
  --border-radius: var(--app-border-radius);
  border-radius: var(--app-border-radius);
  margin: 8px 1px;
  overflow: hidden;
  --box-shadow: none;
  letter-spacing: normal;
  text-transform: none;

  &[fill="clear"] {
    text-decoration: underline;
  }


  &.btn {
    &--big {
      height: var(--app-size-64);
      font-size: 16px;
      line-height: 20px;
    }

    &--narrow {
      height: 32px;
    }

    &--icon {
      .icon {
        margin-right: var(--app-size-8);
        position: relative;
        top: 1px;
      }
    }

    &--copy {
      --border-width: 1px;
      --border-color: var(--ion-color-greyscale-300);
      --border-style: solid;
      --background: var(--ion-color-light);
      --color: var(--ion-color-dark);
      --background-focused: var(--ion-color-light-shade);
      --background-activated: var(--ion-color-light-shade);

      &::part(native) {

      }

      .inner {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        span {
          &:last-of-type {
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: var(--ion-color-light);
            padding: var(--app-size-12);
            border-radius: var(--app-border-radius);
            font-size: 12px;
            box-shadow: 0px 10px 15px -3px rgba(17, 24, 39, 0.07);

            .icon {
              margin-right: var(--app-size-8);
            }

          }
        }
      }
    }

    &--store {
      --padding-start: 0;
      --padding-top: 0;
      --padding-end: 0;
      --padding-bottom: 0;
      --inner-padding-start: 0;
      --inner-padding-top: 0;
      --inner-padding-end: 0;
      --inner-padding-bottom: 0;
      height: 56px;

      img {
        height: 100%;
      }
    }
  }

  &[color="primary-light"] {
    box-shadow: 0px 10px 15px -3px rgba(17, 24, 39, 0.07);
    border: 1px solid var(--ion-color-greyscale-200);
    color: var(--ion-color-greyscale-900);

    &[fill="clear"] {
        border: none;
      box-shadow: none;
    }
  }

  &.back-button {
    text-decoration: none;
    font-size: var(--app-size-16);

    .icon {
      border-radius: var(--app-border-radius);
      margin-right: var(--app-size-8);
      background-color: var(--ion-color-light);
      width: var(--app-size-24);
      height: var(--app-size-24);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}
