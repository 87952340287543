.language-switcher {
  bottom: calc(var(--ion-safe-area-bottom) + 30px);
  left: 50%;
  transform: translateX(-50%);

  ion-list {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ion-item {
      width: 20px;
      height: 20px;
      --min-height: 20px;
      border-radius: 100%;
      overflow: hidden;
      --padding-start: 0;
      --padding-top: 0;
      --padding-end: 0;
      --padding-bottom: 0;
      --inner-padding-start: 0;
      --inner-padding-top: 0;
      --inner-padding-end: 0;
      --inner-padding-bottom: 0;
      opacity: .3;

      + ion-item {
        margin-left: var(--app-size-16);
      }

      &.active {
        opacity: 1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
