.brand-category-grid {
  padding: var(--app-size-16) var(--app-size-16) 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--app-size-8);
  grid-row-gap: var(--app-size-8);

  &.no-padding {
    padding: 0;
  }
}

.section {
  swiper-slide {
    .brand-category-item {
      height: var(--app-size-148);
      padding-top: 0 !important;
    }
  }
}


.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  app-brand-category-item {
    width: calc(100% / 2 - 4px);
    margin-bottom: var(--app-size-8);

    &:nth-child(even) {
      margin-left: var(--app-size-8);
    }

    &:nth-child(5n + 6),
    &:nth-child(6n + 6) {
      margin-bottom: 0;
    }
  }
}

