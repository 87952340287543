.file-box {
  padding: var(--app-size-8) var(--app-size-16) var(--app-size-8) var(--app-size-8);
  border-radius: var(--app-border-radius);
  border: 1px solid var(--ion-color-greyscale-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  + .file-box {
    margin-top: var(--app-size-8);
  }
  &__remove{
    margin-left: var(--app-size-16);
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin: 0;
      flex: 1;
    }

    .icon-wrapper {
      margin-right: var(--app-size-16);
      width: var(--app-size-48);
      height: var(--app-size-48);
      border-radius: var(--app-border-radius);
      background-color: var(--ion-color-greyscale-200);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  &__remove {
    ion-button {
      width: var(--app-size-24);
      height: var(--app-size-24);
      border-radius: 100%;
      --padding-start: 0;
      --padding-end: 0;
      --padding-top: 0;
      --padding-bottom: 0;
      margin: 0;

      .icon {
        margin: 0;
        position: relative;
        top: 1px;
      }
    }
  }
}
