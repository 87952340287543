ion-toast {
  --border-width: 1px;
  --border-style: solid;
  --box-shadow: 0px 25px 50px -12px rgba(17, 24, 39, 0.17);
  --border-radius: var(--app-border-radius);
  color: var(--ion-color-greyscale-600);
  font-size: 14px;
  line-height: 16px;
  //top: var(--app-size-64);
  --min-height: 60px;

  &::part(container) {
    align-items: center;
  }

  &.toast--cancel {
    &::part(button) {
      width: var(--app-size-24);
      height: var(--app-size-24);
      border-radius: 100%;
      background: var(--ion-color-light) url("../../assets/icon/close-icon.svg") no-repeat center center;
      padding: 0;
      display: block;
      margin-right: var(--app-size-8);
      min-height: 0;
    }
  }
  &.toast--settings {
    &::part(button) {
      width: auto;
      height: var(--app-size-24);
      border-radius: var(--app-border-radius);
      background: var(--ion-color-light);
      padding: 0 var(--app-size-8);
      display: block;
      margin-right: var(--app-size-8);
      margin-left: var(--app-size-8);
      font-size: 12px;
    }
  }

  &.toast {
    &--top {
      top: 50px;
    }

    &::part(message) {
      padding-left: var(--app-size-48);
      position: relative;

      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: var(--app-size-32);
        height: var(--app-size-32);
        border-radius: var(--app-border-radius);
        display: block;
      }
    }

    &--danger {
      --background: var(--ion-color-secondary-tint);
      --border-color: var(--ion-color-secondary);

      &::part(message) {
        &:before {
          background: var(--ion-color-secondary) url("../../assets/icon/exclamation-circle-icon.svg") no-repeat center center;
        }
      }
    }

    &--warning {
      --background: var(--ion-color-tertiary-tint);
      --border-color: var(--ion-color-tertiary);

      &::part(message) {
        &:before {
          background: var(--ion-color-tertiary) url("../../assets/icon/exclamation-triangle-icon.svg") no-repeat center center;
        }
      }
    }

    &--success {
      --background: var(--ion-color-success-tint);
      --border-color: var(--ion-color-success);

      &::part(message) {
        &:before {
          background: var(--ion-color-success) url("../../assets/icon/check-circle-icon.svg") no-repeat center center;
        }
      }
    }

    &--info {
      --background: var(--ion-color-greyscale-100);
      --border-color: var(--ion-color-greyscale-900);

      &::part(message) {
        &:before {
          background: var(--ion-color-greyscale-900) url("../../assets/icon/info-circle-icon.svg") no-repeat center center;
        }
      }
    }
  }
}
