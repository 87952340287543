.progress-box {
  position: relative;
  width: 38px;
  height: 38px;
  border: 2px solid var(--ion-color-greyscale-200);
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: var(--app-size-4);
  p {
    margin: 0;
  }
  circle-progress{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 43px;
    height: 43px;
    svg{

    }
  }
}
