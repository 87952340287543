.counter-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--ion-color-greyscale-200);
  border-radius: var(--app-border-radius);
  padding: var(--app-size-16);

  &__label {
    text-align: left;
    margin-top: 0;
    margin-bottom: var(--app-size-16);

  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ion-button {
      margin: 0;
    }
  }

  + .counter-item {
    margin-top: var(--app-size-8);
  }
}
