/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";


@import "./theme/variables";
@import "./theme/custom-ion-colors";
@import "./theme/fonts";
@import "./theme/text";
@import "./theme/components/components";
@import "./theme/helpers";

ion-app {
  background: var(--ion-color-greyscale-100);
}

ion-header {
  &:after {
    display: none !important;
  }

  ion-title {
    ion-img {
      width: 105px;
      margin: 0 auto;
    }
  }

  ion-toolbar {
    --border-color: var(--ion-color-greyscale-200);
  }
}


.gmnoprint {
  display: none;

  a, span {

  }

  div {
    background: none !important;
  }
}

img {
  &[alt="Google"] {
    display: none !important;
  }
}

.accordion {
  swiper-slide {
    .item {
      margin-bottom: 0 !important;
    }
  }
}

ion-content {
  --padding-start: var(--app-size-16);
  --padding-end: var(--app-size-16);

  .content-inner {
    &--empty {
      padding: 0 var(--app-size-16);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &--error {
      padding: 0 var(--app-size-16);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      * {
        width: 100%;
      }
    }
  }
}

* {
  &:focus {
    outline: none !important;
  }
}

ngx-highlight-words {
  span {
    &.highlight {
      background-color: var(--ion-color-warning-tint);
    }
  }
}

.container-inner{
  padding-bottom: 40px;
}
