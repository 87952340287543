.box {
  background-color: var(--ion-color-light);
  padding: var(--app-size-24);
  border-radius: var(--app-border-radius);
  margin: 0 var(--app-size-16);

  ion-button {
    margin-top: var(--app-size-24);
  }

  .icon-wrapper {
    width: var(--app-size-48);
    height: var(--app-size-48);
    border-radius: var(--app-border-radius);
    background-color: var(--bg-color);
    color: var(--ion-color-light);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto var(--app-size-32);
  }

  &--flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    * {
      text-align: left !important;
    }

    .icon-wrapper {
      margin: 0 0 var(--app-size-32);
    }

    ion-button {
      margin-top: 0;
      --padding-start: 0;
      margin-bottom: 0;
    }
  }

}
