.datetime-wrapper {

}


.click-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 62px;
  z-index: 5;
  ion-item{
    z-index: 0;
  }
}
