.code-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    img {
      width: 50%;
    }


    .buttons {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: var(--app-size-40);


      > ion-button {
        margin: 0;
        width: var(--app-size-40);
        height: var(--app-size-40);

        + ion-button {
          margin-top: var(--app-size-8);
        }
      }
    }
  }
}
