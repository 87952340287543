.profile-box {
  padding: var(--app-size-4);
  display: flex;
  flex-direction: row;
  align-items: center;

  &__info {
    h6 {
      margin: 0;
    }

    p {
      margin: 0;
      margin-top: var(--app-size-4);
    }
  }

  &__avatar {
    position: relative;
    border-radius: 100%;
    border: 2px solid var(--ion-color-greyscale-200);
    width: var(--app-size-80);
    height: var(--app-size-80);
    margin-right: var(--app-size-16);

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: var(--app-size-64);
      height: var(--app-size-64);
      border-radius: 100%;
    }

    &--empty {
      img {
        border-radius: 0;

        width: var(--app-size-48);
        height: var(--app-size-48);
      }
    }

    circle-progress {
      position: absolute;
      left: 50%;
      top: calc(50% + 2px);
      transform: translate(-50%, -50%);

      svg {
        width: 85px;
        height: 85px;
      }
    }
  }
}
