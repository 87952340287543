// ROBOTO
@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/r-font/R-Light-font.ttf");
}

@font-face {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/r-font/R-Regular-font.ttf");
}

@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/r-font/R-Medium-font.ttf");
}

@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/r-font/R-Bold-font.ttf");
}

@font-face {
  font-family: "Roboto Black";
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/r-font/R-Black-font.ttf");
}
