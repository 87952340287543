.blog-detail {
  &--inner {
    background-color: var(--ion-color-light);
    padding: 0 var(--app-size-16) var(--app-size-16);

    .back-button {
      margin-left: -16px;
    }
  }

  &__header {
    padding: var(--app-size-8) var(--app-size-16) 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

  }

  &__subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--app-size-16);

    p {
      margin: 0;
    }

    .author {
      display: flex;
      flex-direction: row;
      align-items: center;

    }

    ion-avatar {
      width: var(--app-size-24);
      height: var(--app-size-24);
      margin-right: var(--app-size-8);

      ion-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.empty {
        ion-img {
          object-fit: contain;
        }
      }
    }
  }

  &__image {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: var(--app-border-radius);
    overflow: hidden;
    margin-top: var(--app-size-16);
    position: relative;

    > ion-button {
      position: absolute;
      top: 50%;
      padding: 0;
      margin: 0;
      transform: translateY(-16px);
      left: var(--app-size-16);
      z-index: 999;
      border-radius: 100%;
      width: var(--app-size-32);
      height: var(--app-size-32);
      box-shadow: 0px 20px 25px -5px rgba(17, 24, 39, 0.07);

      .icon {
        position: relative;
        top: 1px;
      }

      &:nth-of-type(2) {
        left: auto;
        right: var(--app-size-16);
      }
    }


    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    swiper-container {
      height: 100%;
      padding: 0;
    }

    &--default {
      background-color: var(--ion-color-greyscale-100);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      img {
        object-fit: contain;
        width: 50%;
        height: auto;
      }
    }
  }

  &__desc {
    padding: var(--app-size-16);

    img {
      height: var(--app-size-160);
      width: 100%;
      border-radius: var(--app-border-radius);
      margin: var(--app-size-32) 0;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }

  }

  &__attachments {
    padding: 0 var(--app-size-16) var(--app-size-40);
    .file-box{
      background-color: var(--ion-color-light);
    }
  }
}
