@import "../../theme/mixins/inputs/checkbox-tag";
@import "../../theme/mixins/inputs/radio-tag";
@import "../../theme/mixins/inputs/color-radio";

.radio-tags {
  margin: 0 -6px;
  @include radio-tag();

  // Add a deeper selector to override default colors
  .radio-tag {
    --radio-tag-color: var(--ion-color-greyscale-600);
    --radio-tag-background: var(--ion-color-light);
    --radio-tag-active-color: var(--ion-color-greyscale-900);
    --radio-tag-active-background: var(--ion-color-black);

 //   padding: 0 var(--page-tags-gutter);
    margin: 0 6px var(--app-narrow-margin);
  }
}

.checkbox-tags {
  margin: 0 -6px;

  @include checkbox-tag();

  .checkbox-tag {
    --radio-tag-color: var(--ion-color-greyscale-600);
    --radio-tag-background: var(--ion-color-light);
    --radio-tag-active-color: var(--ion-color-greyscale-900);
    --radio-tag-active-background: var(--ion-color-black);

    //padding: 0 var(--page-tags-gutter);
    margin: 0 6px var(--app-narrow-margin);
  }

  // Add a deeper selector to override default colors
  &.rounded-checkbox-tags {
    background-color: transparent;

    .checkbox-tag {
      --radio-tag-color: var(--ion-color-greyscale-600);
      --radio-tag-background: var(--ion-color-light);
      --radio-tag-active-color: var(--ion-color-greyscale-900);
      --radio-tag-active-background: var(--ion-color-light);
    }
  }
}


.range-list {
  .range-item {
    position: relative;
    .range-header {
      display: flex;
      position: absolute;
      right: 0;
      top: -31px;

      .range-value {
        font-size: 14px;
        color: var(--ion-color-secondary);
        font-family: var(--ion-font-family-bold);

        + .range-value {
          margin-left: var(--app-fair-margin);
          position: relative;
          &:before{
            position: absolute;
            content: "-";
            left: -11px;
            top: 0;
            color: var(--ion-color-secondary);
          }
        }

      }

      .range-label {
        margin: 0;
        font-size: 12px;
        text-align: left;
        text-transform: uppercase;
        font-family: var(--ion-font-family-black);
      }
    }

    .range-control {
      padding: 0;
      --knob-box-shadow: none;
      --knob-size: 24px;
      --knob-background: var(--ion-color-secondary-shade);
      --ion-text-color: var(--ion-color-primary-tint);
      --bar-background: rgba(var(--ion-color-primary-rgb), .5);
      --bar-height: 6px;
      --bar-border-radius: 6px;

      &::part(bar-active) {
        background: var(--ion-color-primary) !important;
      }

    }
  }
}


form{
  .checkbox-tags {
    width: 100%;
    margin: 0;
    margin-top: 8px;

    .checkbox-tag {
      --border-color: var(--ion-color-greyscale-200);
      flex: 1;
      box-shadow: 0px 4px 6px -1px rgba(17, 24, 39, 0.07);

      .tag-label {
        text-align: left;
        justify-content: flex-start;
        //white-space: normal;
        margin: var(--app-size-12) 0;
      }

      + .checkbox-tag {
        margin-left: var(--app-size-8);
      }
    }

  }
  .radio-tags {
    margin: 0;
    margin-top: 8px;

    .radio-tag {
      --border-color: var(--ion-color-greyscale-200);
      flex: 1;
      box-shadow: 0px 4px 6px -1px rgba(17, 24, 39, 0.07);

      .tag-label {
        text-align: left;
        justify-content: flex-start;
      }

      + .radio-tag {
        margin-left: var(--app-size-8);
      }
    }
    &--placeholders{
      ion-item{
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-end: 0;
        &::part(native){
          border: none
        }
      }
    }

  }
}
