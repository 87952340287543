ion-accordion-group {
  padding: 0 var(--app-size-16);

  ion-accordion {
    border-radius: var(--app-border-radius);

    + ion-accordion {
      margin-top: var(--app-size-8);
    }

    [slot="header"] {
      text-align: left;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: var(--app-size-16);

      h6 {
        margin: 0;
        flex: 1;
      }

      .badge {
        width: var(--app-size-20);
        height: var(--app-size-20);
        background-color: var(--ion-color-secondary);
        color: var(--ion-color-light);
        border-radius: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: var(--app-size-16);

        p {
          margin: 0;
        }
      }

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .icon {
        position: relative;
        top: 2px;
        transition: all .3s ease;
        margin-left: var(--app-size-16);

        &--start {
          transform: none !important;
          margin-right: var(--app-size-8);
          top: 2px !important;
        }
      }

    }

    [slot="content"] {
      &:not(.accordion__content) {
        padding: 0 var(--app-size-16) var(--app-size-16);

        > p {
          margin: 0;
          padding-top: var(--app-size-16);
          border-top: 1px solid var(--ion-color-greyscale-100);

          p {
            margin: 0;
          }
        }
      }

      form {
        margin-top: var(--app-size-16);
      }
    }

    &.accordion-expanded, &.accordion-expanding {
      [slot="header"] {
        .icon {
          transform: rotate(180deg);
          top: -2px;

        }
      }
    }
  }
}

.accordion {
  .empty-box {
    flex-direction: row !important;
    padding: var(--app-size-16) !important;
    .img-wrapper {
      width: var(--app-size-104);
      margin-right: var(--app-size-16);
    }

    h5 {
      flex: 1;
      text-align: left !important;
      color: var(--ion-color-primary);
      font-size: 15px;
    }
  }
}
