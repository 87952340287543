.item {
  overflow: hidden;
  ion-button {
    &[disabled] {
      opacity: 1;
      margin: 0;
    }

    &.btn--icon {
      text-align: right;
      float: right;

      &[fill="clear"] {
        text-decoration: none;
      }
    }
  }

  &__remove {
    position: absolute;
    right: -12px;
    top: -20px;
    box-shadow: 0px 4px 6px -1px rgba(17, 24, 39, 0.07);
    border-radius: 100%;
    z-index: 100;
    width: var(--app-size-40);
    height: var(--app-size-40);
  }
}
