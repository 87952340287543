.segments-toolbar {
  --padding-start: var(--app-size-16);
  --padding-end: var(--app-size-16);
  --padding-top: var(--app-size-8);
  --padding-bottom: var(--app-size-8);

  --border-width: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ion-button {
    text-decoration: none !important;
    --border-width: 1px;
    --border-color: var(--ion-color-greyscale-400);
    --border-style: solid;
    height: 40px;

    + ion-button {
      margin-left: var(--app-size-4);
    }

    &.active {
      --border-color: var(--ion-color-greyscale-900);
    }

    .icon {
      margin-right: var(--app-size-8);
      position: relative;
      top: 1px;
    }
  }
}

ion-segment {
  background: transparent;
  padding: 0;
  margin: 0;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex: 1;

  + ion-button {
    --border-width: 0 !important;
  }
}


