.benefit-card {
  @media only screen and (min-height: 590px) {
    min-width: 460px;
  }
  @media only screen and (min-height: 690px) {
    min-width: 470px;
  }
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  overflow: hidden;
  height: calc(100vw - 64px);
  min-width: 350px;
  transform: rotate(90deg);
  transform-origin: center center;

  &__info {
    background-color: var(--ion-color-greyscale-900);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    padding: var(--app-size-24);

    h4 {
      color: var(--ion-color-light);
      opacity: .9;
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      p {
        margin: 0;
        color: var(--ion-color-light);

        &:last-of-type {
          color: var(--ion-color-greyscale-400);
        }
      }
    }
  }

  &__code {
    @media only screen and (min-height: 590px) {
      padding: var(--app-size-12) var(--app-size-24);
    }
    @media only screen and (min-height: 690px) {
      padding: var(--app-size-24);
    }
    background-color: var(--ion-color-light);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: var(--app-size-24);

    &:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: 20px;
      background-color: var(--ion-color-greyscale-100);
      width: 100%;
      height: 20px;
    }

    > div {
      @media only screen and (min-height: 590px) {
        width: 90%;
      }
      @media only screen and (min-height: 690px) {
        width: 80%;
      }
      width: 80%;
      margin: 0 auto;
      text-align: center;

      svg, img {
        max-width: 100%;
      }
    }
  }

  > div {
    flex: 1;
  }
}
