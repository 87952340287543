.autocomplete-items {
  border: 1px solid var(--ion-color-greyscale-200);
  padding: var(--app-size-8) 0;
  margin: 0;
  list-style: none;
  border-radius: var(--app-border-radius);
  width: 100%;
  margin-top: 4px;
  box-shadow: 0px 10px 15px -3px rgba(17, 24, 39, 0.07);

  highlight-words {
    span {
      font-size: 14px;
      line-height: 16px;
      font-family: var(--ion-font-family-regular);
      color: var(--ion-color-greyscale-900);

      &.highlight {
        font-family: var(--ion-font-family-bold);
      }
    }
  }

  li {
    + li {
      margin-top: var(--app-size-16);
    }
  }

  ion-item {
    font-size: 14px;
    font-family: var(--ion-font-family-regular);
    color: var(--ion-color-greyscale-900);
    --min-height: auto;
    --padding-start: 0;
    --padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --inner-padding-start: 0;
    --inner-padding-top: 0;
    --inner-padding-end: 0;
    --inner-padding-bottom: 0;

    .item-inner {
      display: block;
      padding: var(--app-size-8) var(--app-size-16) ;
      position: relative;
      .autocomplete-click-area{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
      }
    }

    .highlight {
      font-family: var(--ion-font-family-bold);
      background-color: var(--ion-color-warning-tint);
    }
  }

}
