swiper-container{
  &[pagination="true"]{
    padding-bottom: var(--app-size-32) !important;
    --swiper-pagination-bullet-inactive-color: var(--ion-color-greyscale-300);
    --swiper-pagination-color: var(--ion-color-greyscale-700);
    .swiper-pagination{
      bottom: 0px;
    }
  }
}

section{
  swiper-container{
    padding: 0 var(--app-size-16) !important;
    swiper-slide{
      padding: 0 !important;
    }
  }
}
