ion-menu {
  --min-width: 100%;
  --width: 100%;

  &::part(container) {
    background: transparent;
  }

  ion-header {
    ion-toolbar {
      --background: var(--ion-color-light);
      --padding-start: var(--app-size-16);
      --padding-end: var(--app-size-16);
      --padding-top: var(--app-size-16);
      --padding-bottom: var(--app-size-16);
    }

    ion-title {
      padding: 0;
      padding-left: var(--app-size-16);
    }

    ion-buttons {
      ion-button {
        background-color: var(--ion-color-greyscale-200);
        min-height: 40px;
        height: 40px;
        line-height: 40px;
        min-width: 71px;
        font-size: var(--app-size-12) !important;
        padding: var(--app-size-12);
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
          position: relative;
          top: 1px;
          margin-right: var(--app-size-4);
        }
      }
    }
  }

  ion-content {
    --padding-bottom: var(--ion-safe-area-bottom, 0px);

    ion-list {
      margin: var(--app-size-4) 0;

      + ion-list {
        border-top: 1px solid var(--ion-color-greyscale-200);
      }

      ion-item {
        font-size: 16px;
        font-family: var(--ion-font-family-medium);
        color: var(--ion-color-greyscale-500);
        border-radius: var(--app-border-radius);
        --inner-padding-top: var(--app-size-16);
        --inner-padding-bottom: var(--app-size-16);
        --inner-padding-end: 0;
        --padding-start: var(--app-size-16);
        --padding-end: var(--app-size-16);

        &.partners {
          color: var(--ion-color-greyscale-900);
        }

        span {
          color: var(--ion-color-greyscale-900);
        }

        .icon {
          margin-right: 10px;
          position: relative;
          top: 2px
        }

        shield-check-solid-icon {
          color: var(--ion-color-orange);
          position: relative;
          top: 2px;
          margin: 0;
        }

        &.active {
          --background: var(--ion-color-greyscale-100);
          color: var(--ion-color-greyscale-900);
        }
      }
    }
  }
}
