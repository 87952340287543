ion-checkbox {
  //position: absolute;
  --border-radius: var(--app-border-radius);
  --border-color: var(--ion-color-greyscale-300);
  --border-color-checked: var(--ion-color-greyscale-300);
  --border-width: 2px;
  --checkmark-width: 2px;
  margin: 0;
  --size: 24px;
  overflow: hidden;
  left: 0;
}

.item {
  &.checkbox-item {
    --padding-start: 0;
    --padding-end: 0;
    margin: 12px 0 12px 3px;
    position: relative;
    --ripple-color: transparent;
    &::part(native){
      &:after{
        display: none;
      }
    }



    ion-label {
      white-space: initial;
      margin: 0;
      font-size: 14px;
      font-family: var(--ion-font-family-regular);
      line-height: 20px;
      padding-left: calc(var(--app-size-16) + 24px);
    }
  }
}
