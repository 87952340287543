.store-item {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --inner-padding-end: var(--app-size-16);
  --inner-padding-start: var(--app-size-16);
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: var(--app-size-16);
    padding-bottom: var(--app-size-16);
    border-bottom: 1px solid var(--ion-color-greyscale-200);
    .icon{
      position: relative;
      top: 2px;
    }
    p {
      margin: 0;
    }

    .item {
      &__left {
        p{
          .icon{
            margin-left: var(--app-size-4);
          }
          +p{
            margin-top: var(--app-size-4);
          }
        }
      }
      &__right {
        min-width: 100px;
        p {
          text-align: right;
          +p{
            margin-top: var(--app-size-4);
            .icon{
              margin-right: var(--app-size-4);
            }
          }
        }
      }
    }
  }
}

.store-list {
  border-top: 1px solid var(--ion-color-greyscale-200);
}
