ion-loading{
  --background: transparent !important;
  --spinner-color: var(--ion-color-greyscale-900) !important;
  ion-backdrop{
    --backdrop-opacity: .5 !important;
    background-color: var(--ion-color-light) !important;
  }
  .loading-wrapper{
    box-shadow: none !important;
    padding: 0 !important;
  }
}
