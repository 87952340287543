.item-detail {
  &--inner {
    background-color: var(--ion-color-light);
    padding-bottom: var(--app-size-16);
  }

  &__header {
    padding: var(--app-size-8) var(--app-size-16) 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .header {
      &-info {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &-favourites {
        ion-button {
          .icon {
            margin-right: var(--app-size-8);
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }

  &__faq {
    ion-accordion {
      [slot="header"] {
        .icon {
          margin-left: 0 !important;
        }
      }

      [slot="content"] {
        p {
          font-size: 16px;
          line-height: 20px;
        }

        .file-box {
          background-color: var(--ion-color-light);

          &:first-of-type {
            margin-top: var(--app-size-16);
          }

          p {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }

  &__subheader {
    padding: 0 var(--app-size-16);

    .subheader {
      &__info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .item__price {
          p {
            margin: 0;
          }

          .price {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            justify-content: flex-end;

            h5 {
              margin: 0;
              color: var(--ion-color-danger);
            }

            p {
              color: var(--ion-color-danger);
              font-size: 16px;
              font-family: var(--ion-font-family-medium);

              &.price-before-sale {
                text-decoration: line-through;
                font-size: 11px;
                color: var(--ion-color-greyscale-500);
                margin-right: var(--app-size-8);
              }
            }

            &__sale {
              .icon {
                position: relative;
                top: 3px;
              }

              strong {
                font-family: var(--ion-font-family-bold);
              }
            }
          }
        }

        .item__vouchers {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          p {
            margin: 0;
          }

          ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            justify-content: flex-end;
            list-style: none;

            li {
              font-size: 12px;
              font-family: var(--ion-font-family-medium);

            }
          }
        }

      }
    }

    .item__company {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: var(--app-size-16) 0;

      p {
        margin: 0;
      }

      .company {
        &-thumb {
          width: var(--app-size-64);
          height: var(--app-size-64);
          margin-right: var(--app-size-8);

          img {
            width: 100%;
            height: 100%;
            object-position: center center;
            object-fit: contain;
          }

          &-name {
            position: relative;

          }
        }
      }
    }
  }

  &__carousel {
    position: relative;

    > ion-button {
      position: absolute;
      top: 50%;
      padding: 0;
      margin: 0;
      transform: translateY(-16px);
      left: var(--app-size-16);
      z-index: 999;
      border-radius: 100%;
      width: var(--app-size-32);
      height: var(--app-size-32);
      box-shadow: 0px 20px 25px -5px rgba(17, 24, 39, 0.07);

      .icon {
        position: relative;
        top: 1px;
      }

      &:nth-of-type(2) {
        left: auto;
        right: var(--app-size-16);
      }
    }

    swiper-container {
      --bullet-background: transparent;
      --bullet-background-active: var(--ion-color-light);
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: var(--app-size-40);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
        left: 0;
        bottom: 0;
        z-index: 1;
      }

      &[pagination="true"] {
        padding-bottom: 0 !important;
      }

      &::part(pagination) {
        bottom: var(--app-size-8);
      }

      &::part(bullet) {
        border: 1px solid var(--ion-color-light);
        opacity: 1;
        background: transparent;
        width: 7px;
        height: 7px;
      }

      &::part(bullet-active) {
        border: 1px solid var(--ion-color-light);
        opacity: 1;
        width: 7px;
        height: 7px;
        background: var(--ion-color-light);
      }

      swiper-slide {
        padding: 0 !important;
        aspect-ratio: 16/9;
        //height: calc(100vw / 2);
        max-height: 300px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
    }
  }

  &__actions {
    padding: 0 var(--app-size-16);

    .code-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: var(--ion-color-greyscale-200) !important;
      background-image: url("../../../assets/voucher-bg.svg") !important;
      background-repeat: no-repeat !important;
      background-position: left top !important;
      background-size: cover !important;
      padding: 0 var(--app-size-8);

      span {
        font-size: 14px;
        text-align: left;
        flex: 1;

        &.short {
          font-size: 20px;
        }
      }

      > ion-button {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        margin: 0;
        width: 100%;
        transition: width .3s ease;

        &.btn--hide {
          width: 0;
        }
      }

      .action-buttons {
        margin-left: var(--app-size-16);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        ion-button{
          width: 48px;
        }
      }
    }
  }

  &__countdown {
    padding: 0 var(--app-size-16);

    .item {
      &__countdown {
        width: 100%;
        height: 5px;
        background-color: var(--ion-color-greyscale-200);
        display: block;
        position: relative;
        margin-bottom: var(--app-size-8);
        border-radius: var(--app-border-radius);

        &--active {
          background-color: var(--ion-color-greyscale-400);
          border-radius: var(--app-border-radius);
          position: absolute;
          left: 0;
          top: 0;
          width: 70%;
          height: 100%;
          z-index: 1;
          display: block;

        }

      }


      &__countdown-info {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;

        p {
          margin: 0;

          .icon {
            position: relative;
            top: 2px;
          }

          strong {
            font-family: var(--ion-font-family-bold);
          }
        }
      }
    }
  }

  &__desc {
    padding: 0 var(--app-size-16);

    .desc-perex {
      position: relative;

      &:before {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 160px;
        background: linear-gradient(180deg, rgba(244, 245, 245, 0) 0%, #F4F5F5 100%);
      }
    }

    &.open {
      .desc-perex {
        &:before {
          display: none;
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }

    ion-button {
      .icon {
        position: relative;
        top: 2px;
      }
    }
  }
}
