.item {
  &__image {
    width: var(--app-size-64);
    height: var(--app-size-64);
    border-radius: var(--app-border-radius);
    overflow: hidden;
    margin-right: var(--app-size-12);

    ion-img {
      width: 100%;
      height: 100%;

      &::part(image) {
        width: 100%;
      }
    }

    &--img {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }

      &--default {
        text-align: center;
        border: 1px solid var(--ion-color-greyscale-200);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        img {
          width: 60%;
          object-fit: initial;
        }
      }
    }

    &--sale {
      background-color: var(--ion-color-orange);
      color: var(--ion-color-light);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: var(--app-size-8);

      p {
        margin: 0;
        font-size: 8px;
        font-family: var(--ion-font-family-medium);
        line-height: 8px;
        text-align: center;
        width: 100%;
      }

      &.item__image--img {
        padding: 0;
      }
    }

    &--price {
      background-color: var(--ion-color-green);
      color: var(--ion-color-light);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: var(--app-size-8);

      &.item__image--img {
        padding: 0;
      }

      p {
        margin: 0;
        font-size: 8px;
        font-family: var(--ion-font-family-medium);
        line-height: 8px;
        text-align: center;
        width: 100%;
      }
    }
  }

  &__image-large {
    width: 100%;
    overflow: hidden;
    aspect-ratio: 16/9;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;

    }

    &--default {
      text-align: center;

      img {
        width: 30%;
        object-fit: initial;
      }
    }

    ion-skeleton-text {
      margin: 0;
      height: 100%;
    }
  }
}
