
ion-searchbar {
  &.sc-ion-searchbar-ios-h {
    padding: 0 3px;
    margin: 0 -3px;
    width: calc(100% + 6px);
    position: relative;
    height: 58px;
    --background: var(--ion-color-light);
    --placeholder-opacity: 1;
    --placeholder-color: var(--ion-color-greyscale-500);

    .searchbar-input-container.sc-ion-searchbar-ios {
      height: 52px !important;
    }

    &[search-icon='none'] {
      .searchbar-input {
        padding-left: var(--app-size-12) !important;

      }
    }

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 58px;
      bottom: 1px;
      left: 0;
      border-radius: var(--app-border-radius);
      background-color: var(--ion-color-greyscale-300);
      opacity: 0;
      transition: opacity .3s ease;
      z-index: -1;
    }


    &.searchbar-has-focus {
      &::after {
        opacity: 1;
      }

      .searchbar-input {
        border: 2px solid var(--ion-color-primary);
      }
    }


    .searchbar-input {
      height: 52px !important;
      position: relative;
      z-index: 0;
      border: 2px solid var(--ion-color-greyscale-300);
      border-radius: var(--app-border-radius);
      color: var(--ion-color-primary);
      background-color: var(--input-background-color) !important;
      --border-radius: var(--app-border-radius);
      padding-left: 46px !important;
      padding-right: 46px !important;
      font-size: 16px;

      + ion-icon {
        margin-left: 13px !important;
        opacity: 1 !important;
        width: 18px !important;
        color: var(--ion-color-greyscale-500) !important;
      }

      input {
        height: 48px;
      }
    }

    .searchbar-clear-button {
      width: 52px;
    }
  }

}


.searchbox {
  border: 1px solid var(--ion-color-greyscale-400);
  border-radius: 5px;
  box-shadow: 0 25px 50px -12px rgba(17, 24, 39, 0.17);
  background-color: var(--ion-color-light);
  position: absolute;
  z-index: 9999;
  left: 16px;
  top: calc(var(--ion-safe-area-top) + 147px);
  width: calc(100% - 32px);
  max-height: calc(100vh - var(--ion-safe-area-top) - var(--ion-safe-area-bottom) - 56px - 46px - 114px - 100px); /* footerHeight, headerHeight, searchToolbarHeight, common */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .inner {
    padding: var(--app-size-16);

    > p {
      margin: 0;
      color: var(--ion-color-greyscale-600);
      font-family: var(--font-family-medium);

      strong {
        font-family: var(--ion-font-family-black);

      }
    }
    ion-spinner{
      margin: 0 auto;
      color: var(--ion-color-greyscale-600);
      display: block;
    }
  }

  h6 {
    font-size: 14px;
    font-family: var(--font-family-medium);
    line-height: var(--app-size-16);
    margin-left: var(--app-size-16);
    color: var(--ion-color-greyscale-600)
  }

  app-empty-box {
    .empty-box {
      border: none;

      h5 {
        color: var(--ion-color-greyscale-500);

        strong {
          color: var(--ion-color-greyscale-900);
        }
      }

    }
  }

  app-brand-category-item {
    .brand-category-item {
      &__inner {
        flex-direction: row;
      }
    }
  }

  app-item-search {
    .item {
      &.shadow {
        box-shadow: none !important;
      }
    }
  }
}
