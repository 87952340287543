.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-apple {
  --ion-color-base: var(--ion-color-apple);
  --ion-color-base-rgb: var(--ion-color-apple-rgb);
  --ion-color-contrast: var(--ion-color-apple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-apple-contrast-rgb);
  --ion-color-shade: var(--ion-color-apple-shade);
  --ion-color-tint: var(--ion-color-apple-tint);
}

.ion-color-linkedin {
  --ion-color-base: var(--ion-color-linkedin);
  --ion-color-base-rgb: var(--ion-color-linkedin-rgb);
  --ion-color-contrast: var(--ion-color-linkedin-contrast);
  --ion-color-contrast-rgb: var(--ion-color-apple-linkedinast-rgb);
  --ion-color-shade: var(--ion-color-linkedin-shade);
  --ion-color-tint: var(--ion-color-linkedin-tint);
}

.ion-color-grey-50 {
  --ion-color-base: var(--ion-color-greyscale-50);
  --ion-color-base-rgb: var(--ion-color-greyscale-50)
}

.ion-color-grey-100 {
  --ion-color-base: var(--ion-color-greyscale-100);
  --ion-color-base-rgb: var(--ion-color-greyscale-100)
}

.ion-color-grey-200 {
  --ion-color-base: var(--ion-color-greyscale-200);
  --ion-color-base-rgb: var(--ion-color-greyscale-200);
  --ion-color-contrast: var(--ion-color-greyscale-600);
  --ion-color-contrast-rgb: var(--ion-color-greyscale-600-rgb);
  --ion-color-shade: var(--ion-color-greyscale-300);
  --ion-color-tint: var(--ion-color-greyscale-50);
}

.ion-color-grey-300 {
  --ion-color-base: var(--ion-color-greyscale-300);
  --ion-color-base-rgb: var(--ion-color-greyscale-300)
}

.ion-color-grey-400 {
  --ion-color-base: var(--ion-color-greyscale-400);
  --ion-color-base-rgb: var(--ion-color-greyscale-400)
}

.ion-color-grey-500 {
  --ion-color-base: var(--ion-color-greyscale-500);
  --ion-color-base-rgb: var(--ion-color-greyscale-500);
  color: var(--ion-color-greyscale-500);
}

.ion-color-grey-600 {
  --ion-color-base: var(--ion-color-greyscale-600);
  --ion-color-base-rgb: var(--ion-color-greyscale-600)
}

.ion-color-grey-700 {
  --ion-color-base: var(--ion-color-greyscale-700);
  --ion-color-base-rgb: var(--ion-color-greyscale-700)
}

.ion-color-grey-800 {
  --ion-color-base: var(--ion-color-greyscale-800);
  --ion-color-base-rgb: var(--ion-color-greyscale-800);
  color: var(--ion-color-greyscale-800);
}

.ion-color-grey-900 {
  --ion-color-base: var(--ion-color-greyscale-900);
  --ion-color-base-rgb: var(--ion-color-greyscale-900);
  color: var(--ion-color-greyscale-900);
}


.ion-color-red {
  --ion-color-base: var(--ion-color-secondary-shade);
  --ion-color-base-rgb: var(--ion-color-secondary-rgb);
  color: var(--ion-color-secondary);
}



