// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #141C24;
  --ion-color-primary-rgb: 20, 20, 36;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #010102;
  --ion-color-primary-tint: #F4F5F5;

  /** secondary **/
  --ion-color-secondary: #DC2626;
  --ion-color-secondary-rgb: 220, 38, 38;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #861616;
  --ion-color-secondary-tint: #FEE2E2;

  /** tertiary **/
  --ion-color-tertiary: #F97316;
  --ion-color-tertiary-rgb: 249, 115, 22;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #78350F;
  --ion-color-tertiary-tint: #FFEDD5;

  /** success **/
  --ion-color-success: #16a34a;
  --ion-color-success-rgb: 22, 163, 74;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0c5b29;
  --ion-color-success-tint: #DCFCE7;

  /** warning **/
  --ion-color-warning: #FCD34D;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #fae596;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: rgba(23, 23, 23, 0.99);

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: var(--ion-color-greyscale-600);
  --ion-color-light-contrast-rgb: var(--ion-color-greyscale-rgb-600);
  --ion-color-light-shade: #a6a6a6;
  --ion-color-light-tint: #ffffff;

  /** facebook **/
  --ion-color-facebook: #1877F2;
  --ion-color-facebook-rgb: 24, 119, 242;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #1052a6;
  --ion-color-facebook-tint: #68a7fa;

  /** google **/
  --ion-color-google: #cc181e;
  --ion-color-google-rgb: 204, 24, 30;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255, 255, 255;
  --ion-color-google-shade: #b4151a;
  --ion-color-google-tint: #d12f35;

  /** twitter **/
  --ion-color-twitter: #00aced;
  --ion-color-twitter-rgb: 0, 172, 237;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255, 255, 255;
  --ion-color-twitter-shade: #0097d1;
  --ion-color-twitter-tint: #1ab4ef;

  /** apple **/
  --ion-color-apple: #000000;
  --ion-color-apple-rgb: 0, 0, 0;
  --ion-color-apple-contrast: #ffffff;
  --ion-color-apple-contrast-rgb: 255, 255, 255;
  --ion-color-apple-shade: #000000;
  --ion-color-apple-tint: #000000;

  /** linkedIn **/
  --ion-color-linkedin: #2867B2;
  --ion-color-linkedin-rgb: 40, 103, 178;
  --ion-color-linkedin-contrast: #ffffff;
  --ion-color-linkedin-contrast-rgb: 255, 255, 255;
  --ion-color-linkedin-shade: #0e253f;
  --ion-color-linkedin-tint: #6389b6;

  /** greyscale **/
  --ion-color-greyscale-50: #FAFAFA;
  --ion-color-greyscale-rgb-50: 250, 250, 250;

  --ion-color-greyscale-100: #F4F5F5;
  --ion-color-greyscale-rgb-100: 244, 245, 245;

  --ion-color-greyscale-200: #E7E8E9;
  --ion-color-greyscale-rgb-200: 231, 232, 233;

  --ion-color-greyscale-300: #D5D6D7;
  --ion-color-greyscale-rgb-300: 213, 214, 215;

  --ion-color-greyscale-400: #A5A6A6;
  --ion-color-greyscale-rgb-400: 165, 166, 166;

  --ion-color-greyscale-500: #71767A;
  --ion-color-greyscale-rgb-500: 113, 118, 122;

  --ion-color-greyscale-600: #4F585F;
  --ion-color-greyscale-rgb-600: 79, 88, 95;

  --ion-color-greyscale-700: #3B454D;
  --ion-color-greyscale-rgb-700: 59, 69, 77;

  --ion-color-greyscale-800: #232C33;
  --ion-color-greyscale-rgb-800: 35, 44, 51;

  --ion-color-greyscale-900: #141C24;
  --ion-color-greyscale-rgb-900: 20, 28, 36;


  /** font **/
  --ion-font-family-light: "Roboto Light";
  --ion-font-family-regular: "Roboto Regular";
  --ion-font-family-medium: "Roboto Medium";
  --ion-font-family-bold: "Roboto Bold";
  --ion-font-family-black: "Roboto Black";


  /** size **/
  --app-size-4: 4px;
  --app-size-8: 8px;
  --app-size-12: 12px;
  --app-size-16: 16px;
  --app-size-18: 18px;
  --app-size-20: 20px;
  --app-size-24: 24px;
  --app-size-32: 32px;
  --app-size-40: 40px;
  --app-size-48: 48px;
  --app-size-64: 64px;
  --app-size-80: 80px;
  --app-size-96: 96px;
  --app-size-104: 104px;
  --app-size-128: 128px;
  --app-size-132: 132px;
  --app-size-148: 148px;
  --app-size-160: 160px;


  /** other **/
  --app-border-radius: 5px;
  --input-background-color: #FAFAFB;
  --ion-color-green: #14CC80;
  --ion-color-orange: #DD4B39;
  --ion-color-blue: #3B82F6;

}
/*
@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   *

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   *

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   *

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}*/
