.separator {
  position: relative;
  margin: 28px 0;
  min-height: 1px;
  &--full{
    margin: 28px -16px;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--ion-color-greyscale-200);
    left: 0;
    top: 50%;
    z-index: 0;
  }

  p {
    position: relative;
    z-index: 1;
    text-align: center;
    display: block;
    margin: 0;
    color: var(--ion-color-greyscale-500);
    font-size: 14px;
    line-height: 16px;
    font-family: var(--ion-font-family-regular);

    span {
      padding: 0 5px;
      background-color: var(--ion-color-light);
    }
  }
}
