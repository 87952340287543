.company-detail {
  .section {
    padding: 0 var(--app-size-16);
  }

  &--inner {
    background-color: var(--ion-color-light);
    padding-bottom: var(--app-size-16);
  }

  &__header {
    padding: var(--app-size-8) var(--app-size-16) 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .header {
      &-thumb {
        @media only screen and (min-width: 360px) {

          width: var(--app-size-80);
          height: var(--app-size-80);
        }
        width: var(--app-size-64);
        height: var(--app-size-64);
        padding: var(--app-size-16);
        border: 1px solid var(--ion-color-greyscale-200);
        border-radius: var(--app-border-radius);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: var(--app-size-16);

        img {
          width: 100%;
        }
      }

      &-info {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        flex: 1;

        .right{
          a{
            display: block;
            svg{
              width: var(--app-size-24);
              height: var(--app-size-24);
            }
          }
          p{
            margin: 0;
            +p{
              margin-top: var(--app-size-8);
            }
          }
        }

        p, h5 {
          margin: 0;
        }

        p {
          a {
            text-decoration: none;

            > svg {
              width: 16px;
              position: relative;
              top: 3px;

              path {
                fill: var(--ion-color-primary)
              }
            }
          }
        }

        .icon {
          position: relative;
          top: 4px;
          margin-right: var(--app-size-4);
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          &--external {
            top: -2px;
            margin-right: 0;
          }
        }

        h5 {
          + p {
            margin: var(--app-size-8) 0;
          }
        }

        .contacts {
          p {
            + p {
              margin-top: var(--app-size-4);
            }
          }
        }
      }

    }

    &-partner {
      flex-direction: column;

      .header {
        &-thumb {
          border: none;
          padding: 0;
          margin: 0;
        }

        &-info {
          display: flex;
          flex-direction: column;
          align-items: center;

        }
      }
    }
  }

  .separator {
    margin-left: var(--app-size-16);
    margin-right: var(--app-size-16);
  }

  &__socials {
    padding: 0 var(--app-size-16);
    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin: 0;
        padding: 0;
        a{
          display: block;
          svg{
            width: var(--app-size-24);
            height: var(--app-size-24);
          }
        }
      }
    }
  }

  &__desc {
    padding: 0 var(--app-size-16);

    .desc-perex {
      position: relative;

      &:before {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 160px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
      }
    }

    &--short {
      .desc-perex {
        &:before {
          display: none;
        }
      }
    }

    &.open {
      .desc-perex {
        &:before {
          display: none;
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }

    ion-button {
      .icon {
        position: relative;
        top: 2px;
      }
    }
  }
}
