@mixin checkbox-tag() {
  // Default values
  --checkbox-tag-color: #000;
  --radio-tag-background: var(--ion-color-lightest);
  --checkbox-tag-active-color: #FFF;
  --checkbox-tag-active-background: #000;

  .checkbox-tag {
    // Reset values from Ionic (ion-item) styles
    padding: 0 var(--app-size-16);
    box-shadow: none;
    height: 46px;
    border-radius: var(--app-border-radius);
    border: 1px solid var(--ion-color-greyscale-200);
    background: var(--radio-tag-background);
    color: var(--radio-tag-color);
    font-size: 12px;
    font-family: var(--ion-font-family-medium);
    --size: 0;
    --border-width: 0;

    &::part(label) {
      margin-right: 0;
      width: auto;
      min-width: 0;
      flex: 1
    }

    &.rounded-tag {
      --border-radius: 8px;
    }

    span {
      position: relative;
      top: -2px
    }

    &[aria-checked="true"] {
      background: var(--radio-tag-active-background);
      color: var(--radio-tag-active-color);
      border-color: var(--ion-color-greyscale-900);
    }

    &.item-interactive-disabled {
      opacity: 0.5;

      .tag-label {
        // Override Ionic default style
        opacity: 1;
      }
    }

    .tag-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 12px;
      margin: 0;
      font-family: var(--ion-font-family-medium);
      white-space: nowrap;
    }

    ion-checkbox {
      margin: 0px;
      // To hide the .checkbox-icon
      width: 100%;
      --border-width: 0px;
      height: 100%;

      // We cant set width and height for .checkbox-icon .checkbox-inner, so lets hide it changing its color
      --color-checked: transparent;
    }

    &--visible {
      border: 2px solid var(--ion-color-greyscale-200);
      flex: 1;
      --size: 24px;
      --color-checked: transparent;
      box-shadow: 0px 1px 3px rgba(17, 24, 39, 0.07);
      margin-right: var(--app-size-8);
      border-radius: var(--app-border-radius);
      --border-width: 2px;

      &::part(container) {
        top: -2px
      }
    }

    .tag-label {
      font-size: 16px;
      font-family: var(--ion-font-family-medium);
      display: block;

    }
  }
}
