.item-toggle {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --inner-padding-top: var(--app-size-16);
  --inner-padding-bottom: var(--app-size-16);
  --background: transparent;
  --handle-box-shadow: none;
  --handle-height: 28px;
  --handle-width: 28px;
  --border-radius: 60px;
  --handle-spacing: var(--app-size-4);
  font-size: 16px;
  font-family: var(--ion-font-family-medium);
  line-height: var(--app-size-20);
  ion-label {
    &.sc-ion-label-ios-h {
      font-size: 16px;
      font-family: var(--ion-font-family-medium);
      line-height: var(--app-size-20);
    }
  }
/*
  ion-toggle {
    --handle-box-shadow: none;
    --handle-height: 28px;
    --handle-width: 28px;
    --background: var(--ion-color-greyscale-200);
    --border-radius: 60px;
    --handle-spacing: var(--app-size-4);
    height: 36px;
    width: 60px;
  }*/
}
