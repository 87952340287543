.socials-list{
  padding: var(--app-size-24) 0;
  ul{
    margin: 0;
    margin-top: var(--app-size-24);
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    li{
      svg{
        width: 24px;
        path{
          fill: var(--ion-color-greyscale-600)
        }
      }
      +li{
        margin-left: var(--app-size-12);
      }

    }
  }
}
