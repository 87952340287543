.input-item {
  --padding-start: 3px;
  --padding-end: 3px;
  --inner-padding-start: 3px;
  --inner-padding-end: 3px;
  --inner-padding-top: 7px;
  --inner-padding-bottom: 3px;
  position: relative;
  height: auto;
  --background: transparent;
  --inner-border-width: 0 !important;
  --highlight-color-focused: transparent;
  --highlight-color-invalid: transparent;
  --highlight-color-valid: transparent;

  + .input-item {
    margin-top: var(--app-size-16);
  }


  &--datetime {
    + .datetime-wrapper {
      height: 0;
      width: 100%;

      &.open {
        height: auto;
      }

      ion-datetime {
        width: 100%;
      }
    }


  }

  check-circle-solid-icon {
    color: var(--ion-color-success);
  }

  exclamation-circle-solid-icon {
    color: var(--ion-color-danger);
  }

  check-circle-solid-icon, exclamation-circle-solid-icon {
    position: absolute;
    right: var(--app-size-16);
    top: calc(50% - 7px);
    z-index: 3;
  }


  &--autocomplete {
    overflow: initial !important;

    .item-inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      //position: relative;
    }

    ion-searchbar {

      .searchbar-search-icon {
        display: none;
      }
    }

    &::after {
      top: 4px;
      bottom: auto;
    }

    &.ion-invalid {
      input {
        border: 2px solid var(--ion-color-danger) !important;
      }
    }
  }

  &--label-fixed {
    --inner-padding-top: 10px;

    ion-label {
      margin: 0;
      background: var(--ion-color-primary);
      font-size: 11px !important;
      position: absolute;
      display: inline-block;
      padding: var(--app-size-4);
      color: var(--ion-color-light) !important;
      border-radius: var(--app-border-radius);
      left: var(--app-size-18);
      transform: translateY(-24px);
      height: 20px;
      z-index: 2;
      line-height: 12px;
      white-space: nowrap

    }
  }

  &--label-stacked {
    --inner-padding-top: 0;

    ion-label {
      &.sc-ion-label-ios-h {
        font-size: 16px;
        font-family: var(--ion-font-family-medium);
        line-height: 20px;
        margin-bottom: var(--app-size-8);
        margin-top: 0;
        white-space: normal;

        span {
          font-size: 14px;
          color: var(--ion-color-greyscale-500);
          font-family: var(--ion-font-family-regular);
        }
      }
    }

    &::after {
      height: 58px;
    }

    check-circle-solid-icon, exclamation-circle-solid-icon {
      top: auto;
      bottom: var(--app-size-16);
    }

    &-only {
      --min-height: 0 !important;

      &:after {
        display: none;

      }
    }
  }

  .has-focus {
    + .highlight {
      opacity: 1;
    }

    ion-input, ion-textarea {
      /*border: 2px solid var(--ion-color-primary);

      &.valid, &.invalid {
        border: 2px solid var(--ion-color-primary);
      }*/
    }
  }


  ion-input, ion-textarea {
    --placeholder-opacity: 1;
    --placeholder-color: var(--ion-color-greyscale-500);
    position: relative;
    z-index: 1;
    height: 52px;
    border: 2px solid var(--ion-color-greyscale-300);
    border-radius: var(--app-border-radius);
    color: var(--ion-color-primary);
    background-color: var(--input-background-color);
    --border-radius: var(--app-border-radius);
    --padding-top: 0 !important;
    --padding-start: var(--app-size-16) !important;
    --padding-end: var(--app-size-16) !important;
    --padding-bottom: 0 !important;
    font-size: 16px;


    &.valid {
      border: 2px solid var(--ion-color-success);
    }

    &.invalid {
      border: 2px solid var(--ion-color-danger);
    }

    &.has-focus {
      border: 2px solid var(--ion-color-primary);

      &.valid, &.invalid {
        border: 2px solid var(--ion-color-primary);
      }
    }

    input {
      height: 48px;
    }

    label {
      margin: 0;
      height: 100% !important;
    }

    + .highlight {
      position: absolute;
      content: '';
      width: 100%;
      height: 58px;
      bottom: 0;
      left: 0;
      border-radius: var(--app-border-radius);
      background-color: var(--ion-color-greyscale-300);
      opacity: 0;
      z-index: 0;
      transition: opacity .3s ease;
    }
  }

  &--phone {
    position: relative;

    ion-input {
      --padding-start: var(--app-size-64) !important;
      +.highlight{
        bottom: auto;
        top: 4px
      }
      &.input-label-placement-start {
        +.highlight{
          bottom: auto;
          top: 25px
        }
      }
    }

    span {
      position: absolute;
      z-index: 2;
      left: 17px;
      top: 24px;
      color: var(--ion-color-greyscale-500);
    }


    &.input-item--label-stacked {
      span {
        top: 44px;
      }
    }

  }


  ion-textarea {
    height: 132px;
    --padding-top: var(--app-size-16) !important;
    --padding-bottom: var(--app-size-16) !important;

    .textarea-wrapper {
      height: 132px;

      textarea {
        height: 132px;
      }
    }
  }

  &--textarea {
    height: 144px;
    --border-width: 0;
    .highlight {
      height: 140px !important;
    }
    &.input-item--label-stacked {
      height: 167px;


    }


    &::after {
      height: 140px;
    }

    ion-textarea {
      height: 134px
    }


  }
}

app-show-hide-pin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .input-item {
    width: 58px;
    text-align: center;

    + .input-item {
      margin-top: 0;
    }
  }

  .inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  p {
    margin-top: var(--app-size-4);
    margin-bottom: 0;
    margin-left: 3px;
  }

  label {
    font-size: 16px;
    font-family: var(--ion-font-family-medium);
    line-height: 20px;
    margin-bottom: var(--app-size-8);
    margin-top: 0;
    margin-left: 3px;
    position: relative;
    top: -2px
  }
}


form {
  ion-list {
    &.inputs-list {
      margin: 0 -3px !important;

      &--datetime {
        position: relative;
      }
    }
  }
}
